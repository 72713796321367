<template>
  <div class="mainbox">
    <div class="news_box">
      <div class="news_til">
        <div class="news_til_left">
          <div class="news_til_left_logo">
            <img src="~@/assets/img/TechnicalCollege/30.png" alt="">
          </div>
          <div class="news_til_left_til">新闻头条</div>
        </div>
      </div>
      <div style="height: 460px;" class="news_concent">
        <div class="news_concents">
          <div @click="goNext(`/gxGather/components/newsDetail?id=${item.id}`)" v-for="(item, index) in newsList"
            :key="index">
            <div></div>
            <div class="elp">{{ item.newTitle }}</div>
          </div>

        </div>
        <div @click="goNext('/gxGather/components/news')" class="more">查看更多学校新闻
          <img style="width: 12px;height: 12px;margin-left: 5px;" src="~@/assets/img/TechnicalCollege/24.png" alt="">
        </div>
      </div>
    </div>
    <div style="width: 788px;">

      <div style="display: flex;justify-content: space-between;">
        <div style="height: 287px;" class="news_box">
          <div class="news_til">
            <div class="news_til_left">
              <div class="news_til_left_logo">
                <img src="~@/assets/img/TechnicalCollege/120.png" alt="">
              </div>
              <div class="news_til_left_til">热招专业</div>
            </div>
            <div @click="goNext(`/gxGather/majorList`)" class="news_til_right">专业库 <img
                src="~@/assets/img/TechnicalCollege/48.png" alt=""></div>
          </div>
          <div class="majorList">
            <div @click="goNext(`/gxGather/detailIndex?majorId=${item.majorId}`)" style="cursor: pointer;" class="elp"
              v-for="(item, index) in majorList" :key="index">
              {{ item.majorName }}</div>
          </div>
          <div class="lines"></div>
          <div class="news_til">
            <div class="news_til_left">
              <div class="news_til_left_logo">
                <img src="~@/assets/img/TechnicalCollege/119.png" alt="">
              </div>
              <div style="color:#3D6CC8;" class="news_til_left_til">招生层次</div>
            </div>
          </div>
          <div class="majorList">
            <div style="width: 86px;" class="elp" v-for="(item, index) in levelData" :key="index">
              {{ item }}</div>
          </div>
        </div>
        <div style="height: 287px;" class="news_box">
          <div class="news_til">
            <div class="news_til_left">
              <div class="news_til_left_logo">
                <img src="~@/assets/img/TechnicalCollege/45.png" alt="">
              </div>
              <div class="news_til_left_til">常见问题</div>
            </div>
            <!-- <div @click="goNext(`/gxGather/components/question?id=${id}`)" class="news_til_right">查看更多 <img
                src="~@/assets/img/TechnicalCollege/48.png" alt=""></div> -->
          </div>
          <div class="news_concent">
            <div class="news_concents">
              <div v-for="(item, index) in qusetionList" :key="index">
                <div v-show="false"></div>
                <div class="elp">{{ item.questionName }}</div>
              </div>

            </div>
            <div @click="goNext('/gxGather/components/question')" class="more">查看更多常见问题
              <img style="width: 12px;height: 12px;margin-left: 5px;" src="~@/assets/img/TechnicalCollege/24.png"
                alt="">
            </div>

          </div>
        </div>

      </div>
      <div style="margin-top: 20px;">
        <submit></submit>
      </div>
    </div>
  </div>
</template>

<script>
import { Know } from "@/api/know";
import { loginSmsCode } from "@/api/login";
let know = new Know();
import submit from "./submit.vue";

import { getDomain } from "@/api/cookies";
export default {
  components: { submit },
  props: {
    id: '',
  },
  data() {
    return {
      projectCategoryId: '',
      countDown: Date.now() + 1000 * 60,
      downTime: false,

      search: {
        pageSize: 5,
        pageNum: 1,
        projectTypeId: '',
        projectCategoryId: ''
      },
      form: {},
      rules: {
        name: [{ required: true, message: " ", trigger: "blur" }],
        examStage: [{ required: true, message: " ", trigger: "blur" }],
        phone: [
          { required: true, message: " ", trigger: "blur" },
          {
            pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
            message: " ",
            trigger: "blur",
          },
        ],
        code: [{ required: true, message: " ", trigger: "blur" }],
      },
      newsList: [],
      qusetionList: [],
      majorList: [],
      levelData: [],
    };
  },
  created() {
    this.projectCategoryId = localStorage.getItem('projectCategoryId');
    this.search.projectCategoryId = localStorage.getItem('projectCategoryId');
    this.search.projectTypeId = localStorage.getItem('projectTypeId');
    this.projectTypeId = localStorage.getItem('projectTypeId');

    console.log(this.search, 'search');
    know.getContentNewPcList({
      pageSize: 13,
      pageNum: 1,
      projectTypeId: this.projectTypeId,
      projectCategoryId: this.projectCategoryId
    }).then((res) => {
      this.newsList = res.rows
    });
    know.getSchoolProblemPcList({ ...this.search }).then((res) => {
      this.qusetionList = res.rows
    });
    know.getEnrollTuitionPcData({
      projectType: 0,
      projectCategoryId: this.projectCategoryId ? this.projectCategoryId : undefined,

    }).then((res) => {
      this.majorList = res.data.majorList ? res.data.majorList.splice(0, 4) : []
      this.levelData = res.data.levelData ? res.data.levelData.split('/') : []
    });
  },
  mounted() {

  },
  methods: {
    // 获取验证码
    async codeClick() {

      this.countDown = Date.now() + 1000 * 60;
      if (!this.form.phone) {
        this.$message.error("请先输入手机号");
        return;
      } else {
        const res = await loginSmsCode(this.form.phone, "welcomePotential");
        if (res.code === 0) {
          this.$message.success(res.msg);
          this.downTime = true;
        } else if (res.code === 500) {
          this.$message.error("请勿频繁获取！");
        }
      }
    },
    // 倒计时结束
    timeFinish(val) {
      if (val) return (this.downTime = false);
    },
    // 立即提交
    submitClick() {
      this.$refs["form"].validate(async (valid) => {
        if (valid) {
          const res = await know.addSchoolUserConsultation({
            studentName: this.form.name,
            mobile: this.form.phone,
            mobileToken: this.form.code,
            examStage: this.form.examStage,
            consultationClassify: localStorage.getItem("consultationClassify"),
            projectType: "0",
          });
          if (res.code === 0) {
            this.$message.success(res.msg);
            this.form = {}
            this.downTime = false
          } else {
            this.$message.error(res.msg);
          }
        } else {
          this.$message.error("请将信息填写完整 ！");
        }
      });
    },

  },
};
</script>

<style lang="less" scoped>
.mainbox {
  width: 1200px;
  margin: 0 auto;
  padding: 15px 0;
  display: flex;
  justify-content: space-between;

  .news_box {
    width: 380px;
    padding: 20px;
    background: #FFFFFF;
    border-radius: 3px 3px 3px 3px;

    .news_til {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .news_til_left {
        display: flex;
        align-items: center;

        .news_til_left_logo {
          width: 32px;
          height: 32px;
          border-radius: 50%;
          background: #E9F4FF;
        }

        .news_til_left_til {
          font-family: MicrosoftYaHei-Bold, MicrosoftYaHei-Bold;
          font-weight: bold;
          font-size: 18px;
          color: #333333;
          margin-left: 5px;
        }
      }

      .news_til_right {

        font-family: PingFangSC-Regular, PingFangSC-Regular;
        font-weight: normal;
        font-size: 14px;
        color: #777777;
        display: flex;
        align-items: center;
        cursor: pointer;

        img {
          width: 10px;
          height: 10px;
          margin-left: 4px;
        }
      }
    }

    .news_concent {
      // width: 380px;
      height: 220px;
      border-radius: 4px 4px 4px 4px;
      // border: 1px solid #DDDDDD;
      // margin-top: 13px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .news_title {
        display: flex;

        >div {
          width: 50%;
          text-align: center;
          border-bottom: 1px solid #F2F2F2;
          padding: 6px;
          font-family: MicrosoftYaHei-Bold, MicrosoftYaHei-Bold;
          font-weight: normal;
          font-size: 14px;
          color: #555555;
          cursor: pointer;
        }

        .news_choose {
          background: #3D6CC8;
          color: #FFFFFF;
        }


      }

      .news_concents {
        padding: 15px;

        >div {
          display: flex;
          align-items: center;

          font-family: MicrosoftYaHei, MicrosoftYaHei;
          font-weight: normal;
          font-size: 14px;
          color: #444444;
          margin-bottom: 10px;
          cursor: pointer;

          >div:nth-child(1) {
            width: 3px;
            height: 3px;
            background: #E1E1E1;
            border-radius: 50%;
            margin-right: 10px;
            flex-shrink: 0;
          }

          >div:nth-child(2) {
            width: 300px;
          }
        }

        >div:hover {
          color: #BD1212;
        }
      }

      .more {
        width: 244px;
        height: 30px;
        border-radius: 3px 3px 3px 3px;
        border: 1px solid #3D6CC8;

        font-family: PingFangSC-Regular, PingFangSC-Regular;
        font-weight: normal;
        font-size: 14px;
        color: #3D6CC8;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        cursor: pointer;

        &:hover {
          background: rgba(61, 108, 200, 0.1);

        }
      }
    }
  }



}

.mojor_detail {
  width: 382px;
  height: 287px;
  background: #FFFFFF;
  border-radius: 3px 3px 3px 3px;
}

.majorList {
  display: flex;
  flex-wrap: wrap;
  margin-top: 5px;

  >div {
    width: 148px;
    height: 32px;
    background: #F3F7FF;
    border-radius: 3px 3px 3px 3px;
    padding: 2px 5px;

    font-family: MicrosoftYaHei, MicrosoftYaHei;
    font-weight: normal;
    font-size: 14px;
    color: #666666;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    margin-left: 15px;

    &:nth-child(2n) {
      margin-right: 0;
    }
  }
}

.lines {
  width: 347px;
  height: 0px;
  border: 1px solid #EEEEEE;
  margin: 20px auto;
}

img {
  display: block;
  width: 100%;
  height: 100%;
}
</style>