<template>
    <div class="mainboxs">
        <div class="apply_box_til">
            <img style="width: 36px;" src="~@/assets/img/TechnicalCollege/23.png" alt="">
            报名咨询
        </div>
        <div class="form">
            <el-form class="centent_form" :rules="rules" ref="form" :model="form" label-position="left">
                <el-form-item label="学生姓名： " prop="name">
                    <el-input v-model="form.name" placeholder="您的称呼"></el-input>
                </el-form-item>
                <el-form-item label="手机号码：" prop="phone">
                    <el-input v-model="form.phone" placeholder="手机号码"></el-input>
                </el-form-item>
                <el-form-item label="验证码：" prop="code">
                    <el-input v-model="form.code" placeholder="验证码">
                        <span class="yCode" slot="suffix" v-if="!downTime" @click="codeClick">获取验证码</span>
                        <el-statistic v-else slot="suffix" :value="countDown" time-indices format="ss"
                            @finish="timeFinish">
                            <template slot="suffix">s</template>
                        </el-statistic>
                    </el-input>
                </el-form-item>
                <el-form-item prop="examStage" label="报考层次：">
                    <el-select v-model="form.examStage" placeholder="报考层次">
                        <el-option label="高起专" value="高起专"> </el-option>
                        <el-option label="高起本" value="高起本"> </el-option>
                        <el-option label="专升本" value="专升本">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item class="query" label="报名疑问：" prop="query">
                    <el-input v-model="form.query" placeholder="疑问"></el-input>
                </el-form-item>
            </el-form>
            <div @click="submitClick" class="apply_box_btn">提交</div>
        </div>
    </div>
</template>

<script>
import { Know } from "@/api/know";
import { loginSmsCode } from "@/api/login";
let know = new Know();
import { getDomain } from "@/api/cookies";
export default {
    components: {},
    props: {
        id: '',
    },
    data() {
        return {
            countDown: Date.now() + 1000 * 60,
            downTime: false,

            search: {
                pageSize: 8,
                pageNum: 1,
                projectTypeId: this.id
            },
            form: {},
            rules: {
                name: [{ required: true, message: " ", trigger: "blur" }],
                examStage: [{ required: true, message: " ", trigger: "blur" }],
                phone: [
                    { required: true, message: " ", trigger: "blur" },
                    {
                        pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
                        message: " ",
                        trigger: "blur",
                    },
                ],
                code: [{ required: true, message: " ", trigger: "blur" }],
            },
            newsList: [],
            qusetionList: [],
        };
    },
    created() {
        console.log(this.search, 'search');
        know.getContentNewPcList({ ...this.search }).then((res) => {
            this.newsList = res.rows
        });
        know.getSchoolProblemPcList({ ...this.search }).then((res) => {
            this.qusetionList = res.rows
        });
    },
    mounted() {

    },
    methods: {
        // 获取验证码
        async codeClick() {

            this.countDown = Date.now() + 1000 * 60;
            if (!this.form.phone) {
                this.$message.error("请先输入手机号");
                return;
            } else {
                const res = await loginSmsCode(this.form.phone, "welcomePotential");
                if (res.code === 0) {
                    this.$message.success(res.msg);
                    this.downTime = true;
                } else if (res.code === 500) {
                    this.$message.error("请勿频繁获取！");
                }
            }
        },
        // 倒计时结束
        timeFinish(val) {
            if (val) return (this.downTime = false);
        },
        // 立即提交
        submitClick() {
            this.$refs["form"].validate(async (valid) => {
                if (valid) {
                    const res = await know.addSchoolUserConsultation({
                        studentName: this.form.name,
                        mobile: this.form.phone,
                        mobileToken: this.form.code,
                        examStage: this.form.examStage,
                        query: this.form.query,
                        consultationClassify: localStorage.getItem("consultationClassify"),
                        projectType: "0",
                    });
                    if (res.code === 0) {
                        this.$message.success(res.msg);
                        this.form = {}
                        this.downTime = false
                    } else {
                        this.$message.error(res.msg);
                    }
                } else {
                    this.$message.error("请将信息填写完整 ！");
                }
            });
        },

    },
};
</script>

<style lang="less" scoped>
.mainboxs {
    width: 788px;
    height: 230px;
    margin: 0 auto;
    padding: 15px;
    background: linear-gradient(180deg, #FFFFFF 0%, #DBDFE3 100%);
    border-radius: 3px 3px 3px 3px;
    background: linear-gradient(180deg, #FFFFFF 0%, #E1EEFC 100%);
    background-size: 100% 100%;

    .apply_box_til {
        display: flex;
        align-items: center;

        font-family: MicrosoftYaHei-Bold, MicrosoftYaHei-Bold;
        font-weight: bold;
        font-size: 18px;
        color: #3D6CC8;
    }


    .form {
        // padding: 0 20px;



    }



}

img {
    display: block;
    width: 100%;
    height: 100%;
}

.apply_box_btn {
    width: 138px;
    height: 40px;
    background: #1061FF;
    border-radius: 20px 20px 20px 20px;
    margin: 0 auto;
    margin-top: 10px;
    font-family: MicrosoftYaHei, MicrosoftYaHei;
    font-weight: normal;
    font-size: 14px;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.centent_form {
    // margin-top: 57px;
    display: flex;
    flex-wrap: wrap;
    padding-left: 30px;
    // justify-content: center;

    /deep/ .el-form-item:nth-last-child(1) {
        margin-right: 0;
    }

    /deep/ .el-input__suffix {
        right: 16px !important;
    }

    /deep/ .el-form-item {
        display: flex;
        justify-content: space-between;
        margin-right: 20px;

        .el-form-item__label {

            font-family: PingFangSC-Medium, PingFangSC-Medium;
            font-weight: normal;
            font-size: 14px;
            color: #666666;
            padding: 0;
            flex-shrink: 0;
        }

        .el-form-item__label::before {
            content: "";
            margin-right: 0;
        }

        .el-form-item__content {
            width: 140px;
            color: #848484 !important;

            // margin-left: 0px !important;
            .el-input {
                width: 100%;
                color: #848484 !important;

                .el-input__inner {
                    width: 130px;
                    height: 28px;
                    background: #FFFFFF;
                    border-radius: 4px 4px 4px 4px;
                    border: 1px solid #DDDDDD;
                    padding: 0 10px;
                }

                .yCode {

                    font-family: MicrosoftYaHei, MicrosoftYaHei;
                    font-weight: normal;
                    font-size: 12px;
                    color: #1061FF;
                    cursor: pointer;
                }

                .el-statistic {
                    margin: 13px 5px 0 0;
                }

                .number {
                    font-size: 12px;
                    font-family: PingFangSC-Regular-, PingFangSC-Regular;
                    font-weight: normal;
                    color: #333333;
                    letter-spacing: 1px;
                }
            }

            ::placeholder {
                color: #848484 !important;
            }

            // height: 30px;
            // border-radius: 30px;
        }
    }

    /deep/ .query {
        width: 420px;

        .el-form-item__content {
            width: 100%;
            color: #848484 !important;

            // margin-left: 0px !important;
            .el-input {
                width: 100%;
                color: #848484 !important;

                .el-input__inner {
                    width: 100%;
                    background: #FFFFFF;
                    border-radius: 4px 4px 4px 4px;
                    border: 1px solid #DDDDDD;
                    padding: 0 10px;
                }
            }
        }
    }

    /deep/.el-form-item {
        margin-bottom: 10px;
    }
}
</style>