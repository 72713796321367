<template>
  <div class="mainboxs">
    <div class="mainbox">
      <div class="news_til">
        <div class="news_til_left">
          <div class="news_til_left_logo">
            <img src="~@/assets/img/TechnicalCollege/31.png" alt="">
          </div>
          <div class="news_til_left_til">学校推荐</div>
        </div>
        <div @click="goNext('/gxGather/academy')" class="news_til_right">查看更多 <img
            src="~@/assets/img/TechnicalCollege/48.png" alt=""></div>
      </div>
      <div class="line"></div>
      <div style="display: flex;justify-content: space-between;">


        <div class="academy_list">
          <div @click="goNext(`/gxGathers/index?id=${item.id}`)" v-for="(item, index) in majorList" :key="index">
            <div class="school_img">
              <img :src="item.schoolImg" alt="">
              <div class="school_logo">
                <img :src="item.schoolBadge" alt="">
              </div>
            </div>
            <div class="school_detail">
              <div class="school_address">
                <img style="width: 10px;height: auto;margin-right: 5px;" src="~@/assets/img/TechnicalCollege/81.png"
                  alt="">
                {{ item.areaProvinceName }} {{ item.areaCityName }}
              </div>
              <div class="school_name"> {{ item.schoolName }} </div>
              <div class="school_major elp"> <span>招生专业：</span> {{ item.majorName }} </div>
              <div class="lines"></div>
              <div class="school_msg">
                <div>招生简章</div>
                <div>常见问题</div>
                <div>报考咨询</div>
              </div>
            </div>
          </div>
        </div>
        <div class="news_box">
          <div class="news_til">
            <div class="news_til_left">
              <div class="news_til_left_logo">
                <img src="~@/assets/img/TechnicalCollege/25.png" alt="">
              </div>
              <div class="news_til_left_til">热门简章</div>
            </div>
          </div>
          <div class="news_concent">
            <div class="news_concents">
              <div @click="goNext(`/gxGathers/school/enrollmentGuide?enrollId=${item.id}&id=${item.schoolId}`)"
                v-for="(item, index) in newsList" :key="index">
                <div></div>
                <div class="elp">{{ item.name }}</div>
              </div>

            </div>
            <div @click="goNext('/gxGather/brochures')" class="more">查看更多热门简章
              <img style="width: 12px;height: 12px;margin-left: 5px;" src="~@/assets/img/TechnicalCollege/24.png"
                alt="">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Know } from "@/api/know";
let know = new Know();
import { getDomain } from "@/api/cookies";
export default {
  components: {},
  props: {
    whichSchoolId: {
      type: String,
    },
    schoolName: {
      type: String,
    },

  },
  data() {
    return {
      whichNews: 1,
      projectCategoryId: '',
      projectType: '',
      majorList: [],
      newsList: [],
    };
  },
  created() {
    this.projectCategoryId = localStorage.getItem('projectCategoryId');
    this.projectType = localStorage.getItem('projectType');
    this.selectProjectSchoolPcList()
    this.selectProjectBriefsPcList()
  },
  mounted() {

  },
  methods: {
    chooseMajor(i) {
      this.whichNews = i
    },
    selectProjectSchoolPcList() {
      know.selectProjectSchoolPcList({
        pageNum: 1,
        pageSize: 6,
        projectCategoryId: this.projectCategoryId,
      })
        .then((res) => {
          this.majorList = res.rows
        });
    },
    selectProjectBriefsPcList() {
      know.selectProjectBriefsPcList({
        pageNum: 1,
        pageSize: 8,
        projectCategoryId: this.projectCategoryId,
        projectType: this.projectType,
      })
        .then((res) => {
          this.newsList = res.rows
        });
    },

    goPointLink() {
      if (this.link) {
        window.open(this.link, '_blank');

      }
    }

  },
};
</script>

<style lang="less" scoped>
.mainboxs {
  background: #F5F7F9;
  padding: 20px;
  padding-bottom: 0;

}

.mainbox {
  width: 1200px;
  margin: 0 auto;
  padding-top: 15px;


  .news_til {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .news_til_left {
      display: flex;
      align-items: center;

      .news_til_left_logo {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        background: #E9F4FF;
      }

      .news_til_left_til {
        font-family: MicrosoftYaHei-Bold, MicrosoftYaHei-Bold;
        font-weight: bold;
        font-size: 18px;
        color: #333333;
        margin-left: 5px;
      }
    }

    .news_til_right {

      font-family: PingFangSC-Regular, PingFangSC-Regular;
      font-weight: normal;
      font-size: 14px;
      color: #777777;
      display: flex;
      align-items: center;

      img {
        width: 10px;
        height: 10px;
        margin-left: 4px;
      }
    }
  }

  .line {
    width: 1200px;
    height: 0px;
    border-bottom: 1px solid #DCDCDC;
    margin-top: 10px;
    margin-bottom: 20px;
  }

  .academy_list {
    width: 880px;
    display: flex;
    flex-wrap: wrap;

    >div {
      width: 280px;
      height: 286px;
      background: #FFFFFF;
      box-shadow: 0px 3px 11px 1px rgba(7, 15, 32, 0.08);
      border-radius: 6px 6px 6px 6px;
      margin-right: 20px;
      margin-bottom: 20px;
      cursor: pointer;
      transition: transform 0.6s ease;


      .school_img {
        width: 280px;
        height: 114px;
        position: relative;
        border-radius: 6px 6px 0px 0px;

        img {
          border-radius: 6px 6px 0px 0px;
        }

        .school_logo {
          width: 60px;
          height: 60px;
          border-radius: 50%;
          left: 10px;
          bottom: -30px;
          position: absolute;

          img {
            border-radius: 50%;

          }
        }
      }

      .school_detail {
        padding: 20px;
        padding-top: 10px;

        .school_address {
          display: flex;
          justify-content: flex-end;
          align-items: center;

          font-family: MicrosoftYaHei, MicrosoftYaHei;
          font-weight: normal;
          font-size: 12px;
          color: #666666;
        }

        .school_name {

          font-family: MicrosoftYaHei-Bold, MicrosoftYaHei-Bold;
          font-weight: bold;
          font-size: 18px;
          color: #333333;
          margin-top: 10px;
        }

        .school_major {
          width: 230px;

          font-family: MicrosoftYaHei, MicrosoftYaHei;
          font-weight: normal;
          font-size: 12px;
          color: #333333;
          margin-top: 12px;

          span {

            font-family: Microsoft YaHei, Microsoft YaHei;
            font-weight: bold;
            font-size: 12px;
            color: #666666;
          }
        }

        .lines {
          width: 100%;
          height: 0px;
          border: 1px solid #F7F7F7;
          margin: 14px 0;
        }

        .school_msg {
          display: flex;
          justify-content: space-between;

          >div {
            width: 74px;
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;

            font-family: MicrosoftYaHei, MicrosoftYaHei;
            font-weight: normal;
            font-size: 12px;
          }

          >div:nth-child(1) {
            background: url('~@/assets/img/TechnicalCollege/53.png');
            background-size: 100% 100%;
            color: #3D6CC8;

          }

          >div:nth-child(2) {
            background: url('~@/assets/img/TechnicalCollege/54.png');
            background-size: 100% 100%;
            color: #DF932F;

          }

          >div:nth-child(3) {
            background: url('~@/assets/img/TechnicalCollege/55.png');
            background-size: 100% 100%;
            color: #BC3535;

          }
        }
      }

    }

    >div:hover {
      transform: scale(1.1)
    }

    >div:nth-child(3),
    >div:nth-child(7) {
      margin-right: 0;
    }

    .academy_list_item {
      width: 580px;
      height: 286px;
      z-index: 99;

    }
  }


}

img {
  display: block;
  width: 100%;
  height: 100%;
}

.news_box {
  width: 290px;
  padding: 20px;
  background: #FFFFFF;
  border-radius: 3px 3px 3px 3px;
  height: fit-content;
  // padding-bottom: 60px;
  border-radius: 6px 6px 6px 6px;

  .news_til {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .news_til_left {
      display: flex;
      align-items: center;

      .news_til_left_logo {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        background: #E9F4FF;
      }

      .news_til_left_til {
        font-family: MicrosoftYaHei-Bold, MicrosoftYaHei-Bold;
        font-weight: bold;
        font-size: 18px;
        color: #333333;
        margin-left: 5px;
      }
    }

    .news_til_right {

      font-family: PingFangSC-Regular, PingFangSC-Regular;
      font-weight: normal;
      font-size: 14px;
      color: #777777;
      display: flex;
      align-items: center;
      cursor: pointer;

      img {
        width: 10px;
        height: 10px;
        margin-left: 4px;
      }
    }
  }

  .news_concent {
    // width: 380px;
    max-height: 220px;
    border-radius: 4px 4px 4px 4px;
    // border: 1px solid #DDDDDD;
    // margin-top: 13px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .news_title {
      display: flex;

      >div {
        width: 50%;
        text-align: center;
        border-bottom: 1px solid #F2F2F2;
        padding: 6px;
        font-family: MicrosoftYaHei-Bold, MicrosoftYaHei-Bold;
        font-weight: normal;
        font-size: 14px;
        color: #555555;
        cursor: pointer;
      }

      .news_choose {
        background: #3D6CC8;
        color: #FFFFFF;
      }


    }

    .news_concents {
      padding: 15px;

      >div {
        display: flex;
        align-items: center;

        font-family: MicrosoftYaHei, MicrosoftYaHei;
        font-weight: normal;
        font-size: 14px;
        color: #444444;
        margin-bottom: 10px;
        cursor: pointer;

        >div:nth-child(1) {
          width: 3px;
          height: 3px;
          background: #E1E1E1;
          border-radius: 50%;
          margin-right: 10px;
          flex-shrink: 0;
        }

        >div:nth-child(2) {
          width: 300px;
        }
      }

      >div:hover {
        color: #BD1212;
      }
    }

    .more {
      width: 244px;
      height: 30px;
      border-radius: 3px 3px 3px 3px;
      border: 1px solid #3D6CC8;

      font-family: PingFangSC-Regular, PingFangSC-Regular;
      font-weight: normal;
      font-size: 14px;
      color: #3D6CC8;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      cursor: pointer;

      &:hover {
        background: rgba(61, 108, 200, 0.1);

      }
    }
  }
}
</style>