<template>
    <div class="certificateView">
        <img v-if="projectCategoryId == 3" width="100%" src="~@/assets/img/TechnicalCollege/22.png" alt="">
        <img v-if="projectCategoryId == 4" width="100%" src="~@/assets/img/TechnicalCollege/26.png" alt="">

        <news :id="id" class="banner" />
        <!-- <major class="banner" /> -->
        <academy class="banner" />


    </div>
</template>

<script>
import { Know } from "@/api/know";
let know = new Know();
import { getDomain } from "@/api/cookies";
// import banners from "./banner.vue";
import news from "./news.vue";
// import major from "./major.vue";
import academy from "./academy.vue";
// import FootersPartner from "@/components/footersPartner.vue";

export default {
    components: { news, academy },

    data() {
        return {
            projectCategoryId: '',
            mList: []
        };
    },
    created() {

        this.id = localStorage.getItem('projectTypeId');
        this.projectCategoryId = localStorage.getItem('projectCategoryId');
        know.selectProjectTypePullList({ parentId: this.id }).then((res) => {
            this.mList = res.data;
        });
    },
    mounted() { },
    methods: {

    },
};
</script>

<style lang="less" scoped>
.certificateView {}
</style>